import { Mail } from "../common/assets/icons";
import { Conversations } from "../common/assets/icons";
import { Sent } from "../common/assets/icons";
import { Received } from "../common/assets/icons";
import { Users } from "../common/assets/icons";

const AnalyticsComponent = ({ accountAnalytics }) => {
  return (
    <div>
      <div className="py-4">
        <h3 className=" font-semibold text-base text-gray-700">Analytics</h3>
        <p className=" font-normal text-[13px] text-gray-500">
          Stay on top of your inbox, quickly view the activities, and keep track
          of all the conversations that matter to you.
        </p>
      </div>

      <hr className="h-[0.1px] my-2 bg-gray-50"></hr>
      <div className="py-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="flex rounded-xl">
            <div className="flex justify-center items-center">
              <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                <img src={Mail} alt="" />
              </div>
              <div className="ml-6">
                <span className="text-gray-700 font-semibold text-sm whitespace-no-wrap">
                  Messages sent
                </span>
                <p className="text-gray-600 font-normal py-2 text-sm whitespace-no-wrap">
                  {accountAnalytics?.analytics?.messageSent}
                </p>
              </div>
            </div>
          </div>
          <div className="flex rounded-xl">
            <div className="flex justify-center items-center">
              <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                <img src={Received} alt="" />
              </div>
              <div className="ml-6">
                <span className="text-gray-700 font-semibold text-sm whitespace-no-wrap">
                  Open conversations
                </span>
                <p className="text-gray-600 py-2 font-normal text-sm whitespace-no-wrap">
                  {" "}
                  {accountAnalytics?.analytics?.pending}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="flex rounded-xl">
            <div className="flex justify-center items-center">
              <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                <img src={Sent} alt="" />
              </div>
              <div className="ml-6">
                <span className="text-gray-700 font-semibold text-sm whitespace-no-wrap">
                  Resolved conversations
                </span>
                <p className="text-gray-600 py-2 font-normal text-sm whitespace-no-wrap">
                  {" "}
                  {accountAnalytics?.analytics?.resolved}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="flex rounded-xl">
            <div className="flex justify-center items-center">
              <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                <img src={Mail} alt="" />
              </div>
              <div className="ml-6">
                <span className="text-gray-700 font-semibold text-sm whitespace-no-wrap">
                  Messages recieved
                </span>
                <p className="text-gray-600 py-2 font-normal text-sm whitespace-no-wrap">
                  {" "}
                  {accountAnalytics?.analytics?.messageRecieved}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="flex rounded-xl">
            <div className="flex justify-center items-center">
              <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                <img src={Conversations} alt="" />
              </div>
              <div className="ml-6">
                <span className="text-gray-700 font-semibold text-sm whitespace-no-wrap">
                  Active conversations
                </span>
                <p className="text-gray-600 py-2 font-normal text-sm whitespace-no-wrap">
                  {" "}
                  {accountAnalytics?.analytics?.active}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="flex rounded-xl">
            <div className="flex justify-center items-center">
              <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                <img src={Users} alt="" />
              </div>
              <div className="ml-6">
                <span className="text-gray-700 font-semibold text-sm whitespace-no-wrap">
                  Audience
                </span>
                <p className="text-gray-600 py-2 font-normal text-sm whitespace-no-wrap">
                  {" "}
                  {accountAnalytics?.analytics?.audience}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsComponent;
