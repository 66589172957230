import { useEffect, useState } from "react";
import { X } from "react-feather";
import { QRCodeSVG } from "qrcode.react";
import { Whatsapp } from "../../common/assets/icons";
import { WhatsappMix } from "../../common/assets/icons";
import {
  sandboxSubject,
  startListener,
} from "../../common/services/inboxService";
import WabaIntegrationModal from "../waba-integration/waba-integration-modal/demo-modal";
import WabaIntegrationSetupModal from "../waba-integration/waba-integration-setup-modal/setup-modal";
import { getDemoStatus } from "./waba-service";
import WabaNumberSelectionModal from "../waba-integration/waba-number-selection-modal/number-selection";
import { BASE_URL } from "../../../../marketing-cloud-main/src/common/constants/baseUrl";
const loginAccessToken = localStorage.getItem("loginAccessToken");
const USER_ID = localStorage.getItem("userId");
import {
  PUBNUB_PUBLISH_KEY,
  PUBNUB_SUBSCRIBER_KEY,
} from "../../../../marketing-cloud-main/src/common/constants/authToken";
import { getCustomerId } from "../../common/constants/authToken";
import * as Sentry from "@sentry/react";
import { pageLoadEvent } from "../../../../marketing-cloud-container/src/ga-events";
import { MESSAGE } from "../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";

const ConversationsMain = ({ id, navigate, tenantContext }) => {
  const [showQRCode, setQRCodeState] = useState(false);
  const [showWabaModal, setShowWabaModal] = useState(false);
  const [showWabaSetupModal, setShowWabaSetupModal] = useState(false);
  const [demoStatus, setDemoStatus] = useState(null);
  const [demoAPIStatus, setDemoAPPIStatus] = useState();
  const [channelGroupIds, setChannelGroupIds] = useState(null);
  const [showWabaNumbersModal, setShowWabaNumbersModal] = useState(false);
  const [wabaNumbers, setWabaNumbers] = useState();
  const [qrCodeURL, setqrCodeURL] = useState("");
  const [hideHelpGuide, setHideHelpGuide] = useState(false);
  const CUSTOMER_ID = getCustomerId();

  let element: any;
  let source: string = "";
  let whatsappNumber = "2349167797142";
  let message =
    "Hi Terragon Sandbox! Please deliver this message to my WhatsApp test account.";
  let joinCode = "";
  let channelId: any;
  let subjectSubscriber: any;
  let channels: any = [];
  const baseUrl = BASE_URL;

  useEffect(() => {
    window.onmessage = function (e) {
      let data = e.data;
      if (data.type == "buyUnits") {
        navigate(`/billing?product_id=${data.productId}`);
      }
    };
    pageLoadEvent("conversations_page");
  });

  useEffect(() => {
    // startListener(tenantContext.pubnubToken);
    joinCode = "join-" + CUSTOMER_ID + "-" + USER_ID;
    message =
      "Hi Terragon Please deliver my message to the test account of " +
      joinCode;
    let URL = "https://wa.me/" + whatsappNumber + "?text=" + message;
    setqrCodeURL(URL);
    source = "https://devprime.terragoncdp.com/react/";
    channelId = id;
    if (!channelId) {
      channelId = "";
    }
    subjectSubscriber = sandboxSubject.subscribe((value: any) => {
      if (value.type === "sandbox") {
        channels = value.channels[0];
        setQRCodeState(false);
        sendDataToChild([
          {
            verifiedName: "Terragon Sandbox",
            channelGroupId: value.channels[0],
            sandbox: true,
            phoneNumber: whatsappNumber,
            phonenumberId: value.channels[0].split("_")[0],
            pubnubToken: tenantContext.pubnubToken,
          },
        ]);
      }
    });

    fetchDemoStatus();
  }, [channelGroupIds]);

  const handleCloseOnModalSubmit = (event) => {
    setShowWabaModal(false);
    setDemoStatus(event);
  };

  const handleSetupSuccess = (event) => {
    setShowWabaSetupModal(false);
    setWabaNumbers(event);
    setShowWabaNumbersModal(true);
  };

  const setupWaba = (event) => {
    setShowWabaNumbersModal(false);
    setQRCodeState(false);
    console.log("Event after WABA setup", event);
    LoadDemoStatus();
  };

  const sendDataToChild = (accounts?: any) => {
    let data = {
      accounts: accounts,
      businessId: CUSTOMER_ID,
      currentChannel: channelId,
      whatsappEndpoint: `${baseUrl}/message`,
      baseUrl: `${baseUrl}`,
      pubnubPublishKey: PUBNUB_PUBLISH_KEY,
      pubnubSubscribeKey: PUBNUB_SUBSCRIBER_KEY,
      authToken: loginAccessToken,
      userId: USER_ID,
    };

    let interval = setInterval(() => {
      element = document.getElementById("trgn-prm-inbx-embd-rct");
      if (data.accounts && element) {
        element.addEventListener("load", () => {
          element.contentWindow.postMessage(JSON.stringify(data), "*");
          clearInterval(interval);
        });
        element.contentWindow.postMessage(JSON.stringify(data), "*");
        clearInterval(interval);
      }
    }, 500);
  };

  const loadInbox = () => {
    sendDataToChild(channels);
    setQRCodeState(false);
  };

  const fetchDemoStatus = () => {
    if (demoAPIStatus) return;
    LoadDemoStatus();
  };

  const LoadDemoStatus = () => {
    try {
      getDemoStatus()
        .then((res) => {
          try {
            const { error, response = [] } = res;
            if (!error || error === "false") {
              setDemoStatus(response);
              setDemoAPPIStatus(response);
              if (response.whatsappDetails?.length > 0) {
                channels = response.whatsappDetails;
                channelId = id;
                if (!channelId) {
                  channelId = "";
                }
                loadInbox();
              } else {
                setQRCodeState(true);
              }
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
            }
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  return (
    <div>
      {showQRCode ? (
        <div className="flex w-full">
          <div className="w-1/4 text-center">
            <div className="text-gray-500 text-sm mt-96">
              No previous conversations
            </div>
          </div>
          <div className="w-3/4 px-20 py-8 border-t border-l border-gray-100">
            <div
              className={`flex justify-between p-6 bg-gray-25 border border-gray-100 rounded-lg mb-12 ${
                hideHelpGuide && "hidden"
              }`}
            >
              <div className="w-1/2">
                <div className="text-gray-700 text-lg font-semibold mb-2">
                  Need help connecting your WhatsApp Business?
                </div>
                <div className="text-gray-500 leading-6 mb-4">
                  Check our step-by-step guides and learn more about connecting
                  your WhatsApp Business.
                </div>
                <button className="py-3 px-5 text-gray-90 font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500">
                  Learn more
                </button>
              </div>
              <div className="flex gap-4">
                <div className="w-36 h-32 mr-6">
                  <img
                    width="146"
                    height="130"
                    color="#ffffff"
                    src={WhatsappMix}
                    alt=""
                  />
                </div>

                <X
                  className="text-gray-600 -mt-3 -mr-3 cursor-pointer rounded-full hover:bg-gray-30 hover:p-0.5"
                  size={18}
                  onClick={() => setHideHelpGuide(true)}
                />
              </div>
            </div>

            <div className="p-6 bg-gray-25 border border-gray-100 rounded-xl mb-12">
              <div>
                <div className="flex gap-2 items-center">
                  <img src={Whatsapp} alt="" />
                  <div className="text-gray-700 text-lg font-semibold mb-2">
                    Setup a WhatsApp Business account
                  </div>
                </div>
                <div className="text-gray-500 mb-4">
                  Setup your WhatsApp Business account to start communicating
                  with your customers.
                </div>
                {!demoStatus.status ? (
                  <div className="group">
                    <button
                      onClick={() => setShowWabaModal(true)}
                      className={`px-[20px] py-[12px] bg-blue-75 text-white text-[14px] font-semibold leading-4 rounded-lg ${
                        tenantContext.productList["create_waba_policy"] &&
                        "hover:bg-green-75"
                      }  cursor-pointer relative`}
                      disabled={
                        !tenantContext.productList["create_waba_policy"]
                      }
                    >
                      Setup account
                      {!tenantContext.productList["create_waba_policy"] && (
                        <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[-306px] top-[-12px] z-20">
                          {MESSAGE}
                        </div>
                      )}
                    </button>
                  </div>
                ) : null}
                {demoStatus && demoStatus.status === "APPROVED" ? (
                  <div className="group">
                    <button
                      onClick={() => setShowWabaSetupModal(true)}
                      className={`px-[20px] py-[12px] bg-blue-75 text-white text-[14px] font-semibold leading-4 rounded-lg ${
                        tenantContext.productList["create_waba_policy"] &&
                        "hover:bg-green-75"
                      }  cursor-pointer relative`}
                      disabled={
                        !tenantContext.productList["create_waba_policy"]
                      }
                    >
                      Setup account
                      {!tenantContext.productList["create_waba_policy"] && (
                        <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[-306px] top-[-12px] z-20">
                          {MESSAGE}
                        </div>
                      )}
                    </button>
                  </div>
                ) : null}
                {demoStatus && demoStatus.status === "PENDING" ? (
                  <div className="group">
                    <button
                      className={`px-[20px] py-[12px] bg-blue-75 text-white text-[14px] font-semibold leading-4 rounded-lg ${
                        tenantContext.productList["create_waba_policy"] &&
                        "hover:bg-green-75"
                      }  cursor-pointer relative`}
                      disabled={
                        !tenantContext.productList["create_waba_policy"]
                      }
                    >
                      Pending Approval
                      {!tenantContext.productList["create_waba_policy"] && (
                        <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[-306px] top-[-12px] z-20">
                          {MESSAGE}
                        </div>
                      )}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <div>
                {showWabaModal ? (
                  <WabaIntegrationModal
                    handleClose={() => {
                      setShowWabaModal(false);
                    }}
                    handleCloseOnSubmit={(event) => {
                      handleCloseOnModalSubmit(event);
                    }}
                  />
                ) : null}
              </div>
              <div>
                {showWabaSetupModal ? (
                  <WabaIntegrationSetupModal
                    hendleSetupSuccess={(event) => handleSetupSuccess(event)}
                    handleSetupModalClose={() => setShowWabaSetupModal(false)}
                  />
                ) : null}
              </div>
              <div>
                {showWabaNumbersModal ? (
                  <WabaNumberSelectionModal
                    handleWabaNumbersModalClose={() =>
                      setShowWabaNumbersModal(false)
                    }
                    data={wabaNumbers}
                    henldeWabaNumbersModalSuccess={(event) => setupWaba(event)}
                  />
                ) : null}
              </div>
            </div>

            <div className="p-6 bg-gray-25 border border-gray-100 rounded-xl mb-12">
              <div className="text-gray-700 text-lg font-semibold mb-2">
                Test WhatsApp 2-way messaging in 2 simple steps
              </div>
              <div className="text-gray-500">
                You can start testing WhatsApp messaging for free using
                Terragon’s sandbox environment.
              </div>
              <hr className="my-4 -mx-6 border-gray-100" />
              <div className="text-gray-700 font-semibold mb-4">
                Step 1. Scan the QR code with your phone’s camera
              </div>
              <div>
                {qrCodeURL ? (
                  <QRCodeSVG
                    value={qrCodeURL}
                    className="mb-4 cursor-pointer"
                  />
                ) : null}
              </div>
              <div className="text-gray-500">
                Connect and start sending WhatsApp messages by scanning the QR
                code with your phone camera.
              </div>
              <hr className="my-4 -mx-6 border-gray-100" />
              <div className="text-gray-700 font-semibold mb-2">
                Step 2. Send prefilled message
              </div>
              <div className="text-gray-500">
                Send the message prefilled on your WhatsApp and start exploring
                our 2-way conversation.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "calc(100vh - 80px)",
            width: "100%",
            borderTop: "2px solid #e5efff",
          }}
        >
          <iframe
            // src="http://localhost:3000/react"
            src="https://proud-smoke-02ab2f703.5.azurestaticapps.net/react"
            // src="https://whatsapp-inbox-stg.terragoncdp.com/react"
            // src="https://whatsapp-inbox.terragoncdp.com/react/"
            id="trgn-prm-inbx-embd-rct"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default ConversationsMain;
