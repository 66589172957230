import { useEffect, useState } from "react";
import { Select, initTE } from "tw-elements";
import { Icon, Pagination } from "semantic-ui-react";
import {
  addGAEventListener,
  getPageName,
} from "../../../../../marketing-cloud-container/src/ga-events";

const PaginationComponent = (props: any) => {
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    initTE({ Select });
    props.getUpdatedUsersList(activePage, itemsPerPage, props.page);
    // , props.page
    addGAEventListener();
  }, [activePage, itemsPerPage]);

  return (
    <>
      <div className="pr-6 flex items-center gap-5">
        <div className="flex items-center">
          <span className="font-medium text-sm pr-3"> Show </span>

          <div
            className="w-16 triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label="show-dropdown_click"
            data-event-action="button_click"
          >
            <select
              data-te-select-init
              data-te-select-size="lg"
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
                setActivePage(1);
              }}
              className="!p-5"
              value={itemsPerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="30">30</option>
            </select>
          </div>
        </div>

        <Pagination
          defaultActivePage={1}
          activePage={activePage}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true,
          }}
          prevItem={{
            content: (
              <div
                className=" triggerGA"
                data-event-category={`${getPageName()}-list-page`}
                data-event-label={`${getPageName()}-list-pagination-prev_click`}
                data-event-action="button_click"
                data-event-value="Prev"
              >
                <Icon name="angle left" />
                Prev
              </div>
            ),
            icon: true,
          }}
          nextItem={{
            content: (
              <div
                className=" triggerGA"
                data-event-category={`${getPageName()}-list-page`}
                data-event-label={`${getPageName()}-list-pagination-next_click`}
                data-event-action="button_click"
                data-event-value="Next"
              >
                Next
                <Icon name="angle right" />
              </div>
            ),
            icon: true,
          }}
          totalPages={Math.ceil(props.totalUsers / itemsPerPage)}
          onPageChange={(data: any, event: any) => {
            setActivePage(event.activePage);
          }}
        />
      </div>
    </>
  );
};

export default PaginationComponent;
