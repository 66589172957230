import AnalyticsComponent from "../../components/analytics";
import { Whatsapp } from "../../common/assets/icons";
import { openDropdown } from "../../common/utils/UserInterfaceUtils";
import ConversationTableComponent from "../../components/conversations-table";
import WabaIntegrationSetupModal from "../waba-integration/waba-integration-setup-modal/setup-modal";
import WabaNumberSelectionModal from "../waba-integration/waba-number-selection-modal/number-selection";
import { PlusCircle } from "../../common/assets/icons";

import { useEffect, useState, useReducer } from "react";
import {
  getAccountsAndOverview,
  getConversations,
} from "../../common/services/conversation-service";
import PaginationCommon from "../../../../marketing-cloud-main/src/common/components/pagination/pagination";
import * as Sentry from "@sentry/react";
import { MESSAGE } from "../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";

const initialState = {
  accounts: [],
  account: {},
  accountTable: [],
};

const reducerFn = (prevState, action) => {
  if (action.type === "UPDATEACCOUNT") {
    return { ...prevState, account: action.accountItem };
  }

  if (action.type === "UPDATEACCOUNTS") {
    return { ...prevState, accounts: action.accounts };
  }

  if (action.type === "UPDATEACCOUNTTABLE") {
    return { ...prevState, accountTable: action.accountTable };
  }

  return { accounts: [], account: {}, accountTable: [] };
};

const ConversationsOverview = ({ tenantContext }) => {
  useEffect(() => {
    fetchAccountsAndOverview();
  }, []);

  const [accountState, dispatchAccount] = useReducer(reducerFn, initialState);

  const [showWabaSetupModal, setShowWabaSetupModal] = useState(false);
  const [showWabaNumbersModal, setShowWabaNumbersModal] = useState(false);
  const [wabaNumbers, setWabaNumbers] = useState();
  const loginIdToken = localStorage.getItem("loginIdToken");
  const loginAccessToken = localStorage.getItem("loginAccessToken");

  const testingDispatch = (accountItem) => {
    dispatchAccount({ type: "UPDATEACCOUNT", accountItem: accountItem });
  };

  const dispatchTable = (accountItem) => {
    dispatchAccount({ type: "UPDATEACCOUNTTABLE", accountTable: accountItem });
  };

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState({ phonenumberId: "", wabaName: "" });
  const [accountTable, setAccountTable] = useState([]);
  const [accountTotalCount, setAccountTotalCount] = useState("");
  const [dropDownText, setDropDownText] = useState("select account");
  const [phoneNumberId, setPhoneNumberId] = useState();

  const getAccount = (account) => {
    setDropDownText(account.wabaName);
    setAccount(account);
    setPhoneNumberId(account.phonenumberId);
    getAccountTableData(account.phonenumberId, 10, 1);
  };

  const fetchAccountsAndOverview = () => {
    try {
      getAccountsAndOverview()
        .then((res) => {
          const { error, response = {} } = res;
          if (!error) {
            const { data = [] } = response;
            setAccounts(data);
            if (data && data.length) {
              setAccount(data[0]);
              setDropDownText(data[0].wabaName);
              setPhoneNumberId(data[0].phonenumberId);
              getAccountTableData(data[0].phonenumberId, 10, 1);
            }
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  const getAccountTableData = (
    account: number,
    activePage: number,
    itemsPerPage: number
  ) => {
    if (account) {
      try {
        getConversations(account, itemsPerPage, activePage)
          .then((res) => {
            const { error, response = {} } = res;
            setAccountTable(response.data);
            setAccountTotalCount(response.totalCount);
            dispatchTable(response);
          })
          .catch((err) => {
            console.log(err);
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
          });
      } catch (err) {
        console.log(err);
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
      }
    }
  };

  const getConversationsOnPagination = (
    activePage: number,
    itemsPerPage: number
  ) => {
    getAccountTableData(phoneNumberId, activePage, itemsPerPage);
  };
  const handleSetupSuccess = (event) => {
    setShowWabaSetupModal(false);
    setWabaNumbers(event);
    setShowWabaNumbersModal(true);
  };
  return (
    <div id="main" className="px-10 py-5 bg-transparent h-screen">
      <div className="flex flex-wrap justify-end w-full">
        <div className="flex flex-wrap py-2">
          <div className="w-full px-4">
            <div className="relative inline-flex flex-wrap w-full">
              <button
                className="bg-[#F9FAFB] text-gray-700 active:bg-gray-50 font-semibold py-2 px-4 rounded-2xl inline-flex items-center  ease-linear transition-all duration-150"
                type="button"
                onClick={() => openDropdown(event, "account")}
              >
                <span className="mr-16 text-gray-400 text-sm font-normal">
                  {dropDownText}
                </span>
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
              </button>
              <div onClick={() => openDropdown(event, "account")}>
                <button
                  className="hidden bg-white  text-base z-10 min-w-[12rem] float-left py-2 list-none text-left rounded shadow-lg mt-1"
                  id="account"
                >
                  {accounts &&
                    accounts?.length > 0 &&
                    accounts.map((account, i) => {
                      return <AccountDropdown key={i} accountItem={account} />;
                    })}
                  <div className="group">
                    <button
                      onClick={() => setShowWabaSetupModal(true)}
                      className={`flex items-center gap-2 text-sm px-[20px] py-[12px] text-gray-700 text-[14px] leading-4 cursor-pointer relative`}
                      disabled={
                        !tenantContext.productList["create_waba_policy"]
                      }
                    >
                      <img src={PlusCircle} alt="" />
                      Add new account
                      {!tenantContext.productList["create_waba_policy"] && (
                        <div className="absolute hidden group-hover:flex text-white bg-black p-3 text-xs rounded-lg w-[300px] right-[180px] top-[-6px] z-20">
                          {MESSAGE}
                        </div>
                      )}
                    </button>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-wrap py-2">
          <div className="w-full px-4">
            <div className="relative inline-flex flex-wrap w-full">
              <button
                className="bg-[#F6F9FC] text-gray-700 active:bg-gray-100 font-semibold py-2 px-4 rounded-2xl inline-flex items-center  ease-linear transition-all duration-150"
                type="button"
                onClick={() => openDropdown(event, "date")}
              >
                <span className="mr-4 text-gray-600 text-sm font34-semibold">
                  Date
                </span>
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
              </button>
              <div
                className="hidden bg-white  text-base z-50 min-w-[12rem] float-left py-2 list-none text-left rounded shadow-lg mt-1"
                id="date"
              >
                <a
                  href="#"
                  className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                >
                  Action
                </a>
                <a
                  href="#"
                  className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                >
                  Another action
                </a>
                <a
                  href="#"
                  className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                >
                  Something else here
                </a>
                <div className="h-0 my-2 border border-solid border-t-0 border-blueGray-800 opacity-25"></div>
                <a
                  href="#"
                  className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                >
                  Seprated link
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="relative inline-block text-left px-4">
          <div className="py-1">
            <div className="group inline-block relative">
              <button className="bg-white border-2 border-[#F2F4F7] text-gray-700 font-semibold py-2 px-4 rounded-lg inline-flex items-center">
                <span className="mr-4 text-gray-600 font-semibold text-sm">
                  Download report
                </span>
              </button>
            </div>
          </div>
        </div> */}
      </div>
      <AnalyticsComponent accountAnalytics={account} />
      <ConversationTableComponent
        conversationsTabledata={accountTable}
        phoneNumberId={account?.phonenumberId}
      />
      <div className="flex pb-10">
        <PaginationCommon
          totalUsers={accountTotalCount}
          getUpdatedUsersList={getConversationsOnPagination}
        />
        <div>
          {showWabaSetupModal ? (
            <WabaIntegrationSetupModal
              hendleSetupSuccess={(event) => handleSetupSuccess(event)}
              handleSetupModalClose={() => setShowWabaSetupModal(false)}
            />
          ) : null}
        </div>
        <div>
          {showWabaNumbersModal ? (
            <WabaNumberSelectionModal
              handleWabaNumbersModalClose={() => setShowWabaNumbersModal(false)}
              data={wabaNumbers}
              henldeWabaNumbersModalSuccess={(event) => {}}
            />
          ) : null}
        </div>
      </div>
    </div>
  );

  function AccountDropdown({ accountItem }) {
    return (
      <div
        onClick={() => {
          getAccount(accountItem);
          testingDispatch(accountItem);
        }}
      >
        <div className="px-4 py-2 w-full">
          <span className="flex">
            <img src={Whatsapp} alt="" />
            <span className=" font-medium text-sm text-gray-700 py-1 px-2">
              {" "}
              {accountItem?.wabaName}{" "}
            </span>
          </span>
          <p className=" font-normal text-xs text-gray-400 pl-8">
            {" "}
            {accountItem?.analytics?.messageSent} messages{" "}
          </p>
        </div>
      </div>
    );
  }
};
export default ConversationsOverview;
