import { createPopper } from "@popperjs/core";


export const openDropdown = (event, dropdownID) => {
  let element = event.target;
  while (element.nodeName !== "BUTTON") {
    element = element.parentNode;
  }
  var popper = createPopper(element, document.getElementById(dropdownID), {
    placement: 'bottom-start'
  });
  document.getElementById(dropdownID).classList.toggle("hidden");
  document.getElementById(dropdownID).classList.toggle("block");
}

export const getNameInitials = (nameString: string): string => {
  nameString = nameString?.trim() || ''
  let rv = nameString.charAt(0)
  const splittedName = nameString.replace(/\s+/g, ' ').split(' ') || []

  if (splittedName.length > 1) {
    rv += splittedName[1].charAt(0)
  }

  return rv.toUpperCase()
}
