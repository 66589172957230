import env from "../../../env.json";

export const AUTH_TOKEN =
  'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjY1LCJlbWFpbCI6InFhYml6ZW5zZXRlc3RAZ21haWwuY29tIiwiYnVzaW5lc3NfaWQiOjEzMywicm9sZV9pZCI6MywicmVzb3VyY2VfcGVybWlzc2lvbnMiOnt9LCJpYXQiOjE2ODUwMDIzODUsImV4cCI6MTY4NTYwNzE4NSwiaXNzIjoiVGVycmFnb24gTWFya2V0aW5nIFBsYXRmb3JtIn0.6nhEQoBf5nDTpumNZP_VZTc-kSiYUxmOAoGZs2UZVJbYL7i_g8vhAOPb9ilMistYnHudUDvzgRC-kGuQdBmIHQ';
export const SESSION_TOKEN = '';
export const ID_TOKEN =
  'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI2U2dpOGdZdDhDOFNOb0ItR3B1LVVKcnBYOEV3UVpwOE4tNGYxRjYzWU1BIn0.eyJleHAiOjE2ODQzNDE4OTIsImlhdCI6MTY4NDMwNzAyMywiYXV0aF90aW1lIjoxNjg0MzA1ODkyLCJqdGkiOiI4ZjU1ZDNjZi00ZmFhLTQ5YWEtYjYwMi0zNjNkNmQyZDFkNDkiLCJpc3MiOiJodHRwczovL3N0YWdpbmcuYXV0aC50ZXJyYWdvbmx0ZC5jb20vcmVhbG1zL21hc3RlciIsImF1ZCI6ImRlbW8iLCJzdWIiOiI1MThkZDgzNy0wNjQ0LTQwYmQtYjgzMS03OTY3YTI1MGRlN2QiLCJ0eXAiOiJJRCIsImF6cCI6ImRlbW8iLCJub25jZSI6IjI0MTgzZGQxLTcxOTEtNDI5OC04MzliLThiODQ5MGU2NjM1ZiIsInNlc3Npb25fc3RhdGUiOiJiMTQ5ZTI4Zi00Y2Q4LTQ0YzgtOGE4NC0zNmVkMTBhMWYwYjkiLCJhdF9oYXNoIjoiUVBmZjB3bGN6UkZiQ2V0cEl2N3d4ZyIsImFjciI6IjAiLCJzaWQiOiJiMTQ5ZTI4Zi00Y2Q4LTQ0YzgtOGE4NC0zNmVkMTBhMWYwYjkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6IkFuc2h1bCBqYWluIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiYWRtaW4iLCJnaXZlbl9uYW1lIjoiQW5zaHVsIiwiZmFtaWx5X25hbWUiOiJqYWluIiwiZW1haWwiOiJhamFpbkB0ZXJyYWdvbmx0ZC5jb20ifQ.idGvHo-qHtJpDt83RPJPYeDvHvGDKlgqDxyG5VkPw272I3SMAL58rjrEMGeccfQXUN-lTi8jx6mkB9m8Ex9T9D66ZmFq5rt6Su0bL3HoC3LVDuuc7OloAdD6i80MXfjCd6Xwc2IyYbpWSbe1TOtDdRbhO8YZeUmlAK63CvjJlvCq6hyDoa9UyXo2lTwAOR3h1XhCvAk80Lff-zvQwsSK45yJ7xaHcshIYaToB18NqDif_nJ4f6CKf6x15WoL4AHvPSyn7ZWDxPV7Ty0I3SMq70sWxGSBUia0rqVNB9eTIZYhFuw7rIzZS8VzvZ6-4KDhfbWU-v2WtSH04CgLzHZePA';
export const CLIENT_ID = env.CLIENT_ID;

// export const PAYSTACK_PUBLIC_KEY = 'pk_live_4b18564201f0ac69863f564dc69b43f21947b02e';
export const PAYSTACK_PUBLIC_KEY = env.PAYSTACK_PUBLIC_KEY;
export const PUBNUB_PUBLISH_KEY = env.PUBNUB_PUBLISH_KEY;
export const PUBNUB_SUBSCRIBER_KEY = env.PUBNUB_SUBSCRIBER_KEY;

export const getCustomerId = () => {
  return localStorage.getItem('businessId')
    ? Number(localStorage.getItem('businessId'))
    : 0;
}
