import { useEffect, useState } from "react";
import "./demo-modal.css";
import { getIndustries } from "../../conversations-main/waba-service";
import { addDemo } from "../../conversations-main/waba-service";
import { getDemoStatus } from "../../conversations-main/waba-service";
import { Whatsapp } from "../../../common/assets/icons";
import * as Sentry from "@sentry/react";
import { Close } from "../../../common/assets/icons";
import { capitalizeFirstLetter } from "../../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";

const WabaIntegrationModal = (props: any) => {
  const [businessName, setBusinessName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [industry, setIndustry] = useState(null);
  const [businessRegistered, setbusinessRegistered] = useState(true);
  const [businessFacebook, setBusinessFacebook] = useState(true);
  const [whatsappNumber, setWhatsappNumber] = useState(true);
  const [industries, setIndustries] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const businessNameOnChange = (event) => {
    setBusinessName(event.target.value);
  };

  const businessEmailOnChange = (event) => {
    setBusinessEmail(event.target.value);
  };

  const businessAddressOnChange = (event) => {
    setBusinessAddress(event.target.value);
  };

  const industryOnChange = (event) => {
    setIndustry(event.target.value);
  };

  const businessRegisteredOnChange = (value) => {
    setbusinessRegistered(value);
  };

  const businessFacebookOnChange = (value) => {
    setBusinessFacebook(value);
  };

  const whatsappNumberOnChange = (value) => {
    setWhatsappNumber(value);
  };

  const fetchIndustries = () => {
    if (industries.length > 0) return;
    try {
      getIndustries()
        .then((res) => {
          const { error, response = [] } = res;
          if (!error || error === "false") {
            let industries = [];
            response.map((element) => {
              industries.push({
                label: capitalizeFirstLetter(element.industry),
                value: element.industry,
              });
            });
            setIndustries(industries);
            setIndustry(industries[0].value);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchIndustries();
  }, []);

  const submitData = () => {
    setSubmitClicked(true);
    if (
      businessName == "" ||
      businessAddress == "" ||
      businessEmail == "" ||
      industry === null
    )
      return;
    let data = {
      businessName: businessName,
      businessEmail: businessEmail,
      businessAddress: businessAddress,
      industry: industry,
      isRegistered: businessRegistered ? "Yes" : "No",
      isFbManagerAvailable: businessFacebook ? "Yes" : "No",
      isPhonenumberAvailable: whatsappNumber ? "Yes" : "No",
    };
    console.log(data);
    try {
      addDemo(data)
        .then((res) => {
          const { error, response = [] } = res;
          if (!error || error === "false") {
            console.log(response);
            fetchDemoStatus();
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log("Error");
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  const fetchDemoStatus = () => {
    try {
      getDemoStatus()
        .then((res) => {
          const { error, response = [] } = res;
          if (!error || error === "false") {
            console.log(response);
            props.handleCloseOnSubmit(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  useEffect(() => {});

  return (
    <div>
      <div className="absolute w-screen h-screen left-0 top-0">
        <div className="modal-container flex h-full w-full justify-center items-center">
          <div className="modal w-1/2  bg-white rounded-md overflow-hidden">
            <div className="height-100">
              <div className="height-100">
                <div className="whatsapp-header-container flex justify-between">
                  <div className="whatsapp-logo-container h-16 w-16 rounded-full flex justify-center items-center">
                    <img
                      height={30}
                      width={30}
                      color="#ffffff"
                      src={Whatsapp}
                      alt=""
                    />
                  </div>
                  <div className="close-icon" onClick={props.handleClose}>
                    <img src={Close} alt="" />
                  </div>
                </div>
                <div className="form-container p-8 overflow-auto">
                  <div className="heading1 mt-8 text-base font-semibold">
                    Setup WhatsApp Business account
                  </div>
                  <div className="heading2">
                    Book a demo to create your WhatsApp Business account.
                  </div>
                  <div className="display-flex">
                    <div className="flex-basis-50">
                      <div className="font-14-bold">Name of your business</div>
                      <div
                        className={
                          submitClicked && businessName == ""
                            ? "input-container-error1"
                            : "margin-top-4 input-container"
                        }
                      >
                        <input
                          className="input-field font-14-thin"
                          type="text"
                          placeholder="Your business name"
                          value={businessName}
                          onChange={businessNameOnChange}
                        />
                      </div>
                    </div>
                    <div className="flex-basis-50">
                      <div className="font-14-bold">Business email</div>
                      <div
                        className={
                          submitClicked && businessEmail == ""
                            ? "input-container-error1"
                            : "margin-top-4 input-container"
                        }
                      >
                        <input
                          className="input-field font-14-thin"
                          type="text"
                          placeholder="Your business email"
                          value={businessEmail}
                          onChange={businessEmailOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="display-flex margin-top-24">
                    <div className="flex-basis-50">
                      <div className="font-14-bold">Business address</div>
                      <div
                        className={
                          submitClicked && businessAddress == ""
                            ? "input-container-error1"
                            : "margin-top-4 input-container"
                        }
                      >
                        <input
                          className="input-field font-14-thin"
                          type="text"
                          placeholder="Your business address"
                          value={businessAddress}
                          onChange={businessAddressOnChange}
                        />
                      </div>
                    </div>
                    <div className="flex-basis-50">
                      <div className="font-14-bold">Industry</div>
                      <div
                        className={
                          submitClicked && industry == null
                            ? "input-container-error1"
                            : "margin-top-4 input-container"
                        }
                      >
                        {industries.length > 0 ? (
                          <select
                            className="input-field font-14-thin"
                            value={industry}
                            onChange={industryOnChange}
                          >
                            {industries.map((categoryItem, i) => {
                              return (
                                <option
                                  key={i}
                                  value={categoryItem.value}
                                  selected={categoryItem.value === industry}
                                >
                                  {categoryItem.label}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <div className="flex items-center p-2 h-full text-[#a3adbb]">
                            No industries available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="margin-top-24">
                    <div className="font-14-bold">
                      Is your business legally registered?
                    </div>
                    <div className="margin-top-4 flex-gap-40">
                      <label className="flex-gap-8 font-14-thin">
                        <input
                          className="radio-accent"
                          type="radio"
                          onChange={() => businessRegisteredOnChange(true)}
                          checked={businessRegistered}
                        />
                        Yes
                      </label>
                      <label className="flex-gap-8 font-14-thin">
                        <input
                          type="radio"
                          className="radio-accent"
                          onChange={() => businessRegisteredOnChange(false)}
                          checked={!businessRegistered}
                        />
                        No
                      </label>
                    </div>
                  </div>
                  <div className="margin-top-24">
                    <div className="font-14-bold">
                      Do you have a Facebook business manager account?
                    </div>
                    <div className="margin-top-4 flex-gap-40">
                      <label className="flex-gap-8 font-14-thin">
                        <input
                          type="radio"
                          className="radio-accent"
                          onChange={() => businessFacebookOnChange(true)}
                          checked={businessFacebook}
                        />
                        Yes
                      </label>
                      <label className="flex-gap-8 font-14-thin">
                        <input
                          type="radio"
                          className="radio-accent"
                          onChange={() => businessFacebookOnChange(false)}
                          checked={!businessFacebook}
                        />
                        No
                      </label>
                    </div>
                  </div>
                  <div className="margin-top-24">
                    <div className="font-14-bold">
                      Do you have a phone number that has never been used on
                      WhatsApp?
                    </div>
                    <div className="margin-top-4 flex-gap-40">
                      <label className="flex-gap-8 font-14-thin">
                        <input
                          type="radio"
                          className="radio-accent"
                          onChange={() => whatsappNumberOnChange(true)}
                          checked={whatsappNumber}
                        />
                        Yes
                      </label>
                      <label className="flex-gap-8 font-14-thin">
                        <input
                          type="radio"
                          className="radio-accent"
                          onChange={() => whatsappNumberOnChange(false)}
                          checked={!whatsappNumber}
                        />
                        No
                      </label>
                    </div>
                  </div>
                  <div className="buttons-container">
                    <div className="back-button" onClick={props.handleClose}>
                      Back
                    </div>
                    <div className="book-demo-button" onClick={submitData}>
                      Book your demo
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WabaIntegrationModal;
