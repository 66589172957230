export const pageLoadEvent = (eventCategory: string) => {
  (window as any).gtag('event', 'page-load', {
    event_category: eventCategory,
  });
};

export const clickGAEvent = (
  eventAction: string,
  eventLabel: string,
  eventCategory: string,
  eventValue?: string
) => {
  (window as any).gtag('event', eventAction, {
    event_category: eventCategory,
    event_label: eventLabel,
    event_value: eventValue,
  });
};

const initClickGAEvent = (event: Event) => {
  const target = event.currentTarget as HTMLElement;
  const eventAction = target.getAttribute('data-event-action') ?? 'error';
  const eventLabel = target.getAttribute('data-event-label') ?? 'error';
  const eventCategory = target.getAttribute('data-event-category') ?? 'error';
  const eventValue = target.getAttribute('data-event-value') ?? 'error';

  eventValue == 'error' && eventValue
    ? clickGAEvent(eventAction, eventLabel, eventCategory)
    : clickGAEvent(eventAction, eventLabel, eventCategory, eventValue);
  // event.stopPropagation();
};

export const getPageName = () => {
  let pageName = window.location.pathname;
  pageName == '/'
    ? (pageName = 'dashboard')
    : (pageName = pageName.split(' ').join('').slice(1).replace('/', '-'));
  return pageName;
};

let eventListeners: Function[] = [];
export const addGAEventListener = () => {
  const elements: HTMLCollectionOf<Element> =
    document.getElementsByClassName('triggerGA');
  for (let index = 0; index < elements.length; index++) {
    const elem: Element = elements[index];
    elem.addEventListener('click', initClickGAEvent);
    eventListeners.push(() => {
      elem.removeEventListener('click', initClickGAEvent);
    });
  }
};

export const removeGAEventListener = () => {
  eventListeners.forEach((removeElemListener) => removeElemListener());
  eventListeners = [];
};
