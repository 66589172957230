import PubNub from 'pubnub';
import { Subject } from 'rxjs';
import { getCustomerId } from '../constants/authToken';
import {
  PUBNUB_PUBLISH_KEY,
  PUBNUB_SUBSCRIBER_KEY,
} from '../../../../marketing-cloud-main/src/common/constants/authToken';

const USER_ID = localStorage.getItem('userId');

interface PubnubConfig {
  subscribeKey: string;
  uuid: string;
  publishKey?: string | undefined;
}

interface sandboxNotification {
  type: string;
  message: number;
  timetoken: string;
  channels: any;
}

const pubnub = new PubNub({
  publishKey: PUBNUB_PUBLISH_KEY,
  subscribeKey: PUBNUB_SUBSCRIBER_KEY,
  uuid: `${getCustomerId()}`,
} as PubnubConfig);

export const sandboxSubject = new Subject();

const addWhatsappMessageToList = (event: any) => {
  if (event.userMetadata.type === 'sandbox') {
    let message: sandboxNotification = {
      type: event.userMetadata.type,
      message: event.message.text,
      timetoken: new Date(event.timetoken / 10000).toString(),
      channels: event.message.channels,
    };
    sandboxSubject.next(message);
  }
};

const handleMessage = (event: any) => {
  addWhatsappMessageToList(event);
};

const handleStatus = (event: any) => {};

const pubnubListener = {
  status: handleStatus,
  message: handleMessage,
};

export const startListener = (pubnubToken) => {
  pubnub.setToken(pubnubToken);
  pubnub.addListener(pubnubListener);
  const CUSTOMER_ID = getCustomerId();

  let channel = CUSTOMER_ID
    ? 'mc_' + CUSTOMER_ID + '_' + USER_ID
    : 'mc_' + USER_ID;
  pubnub.subscribe({
    channels: [channel, channel + '_' + 'qa'],
  });
};

export const stopListener = () => {
  const CUSTOMER_ID = getCustomerId();
  let channel = CUSTOMER_ID
    ? 'mc_' + CUSTOMER_ID + '_' + USER_ID
    : 'mc_' + USER_ID;
  pubnub.unsubscribe({
    channels: [channel, channel + '_' + 'qa'],
  });
  pubnub.removeListener(pubnubListener);
  pubnub.stop();
};
