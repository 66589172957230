import "./setup-modal.css";
import { fbLogin } from "../../conversations-main/waba-service";
import { Whatsapp } from "../../../common/assets/icons";
import { getPhoneNumbersForWABA } from "../../conversations-main/waba-service";
import { Close } from "../../../common/assets/icons";
import * as Sentry from "@sentry/react";
declare const FB: any;

const WabaIntegrationSetupModal = (props) => {
  FB.init({
    appId: "295412412010020",
    cookie: true,
    xfbml: true,
    version: "v18.0",
  });

  const fbLoginCall = () => {
    fbLogin(FB).then((response: any) => {
      console.log("Facebook Response", response);
      sampleResponse(response.code);
      // this.fbUserAccessToken = response.accessToken;
      // getPhoneNumbers(response.accessToken);
      // this.faceBookEndFlow = true;
      // this.thirdBox = false;
      // this.secondBox = false;
      // this.getallwabasShow();
    });
    // let data = {
    //     token: 'EAAEMrQZChTiQBAA0ssQwaK0CZCKDgfQDIZB494SOEHiQmjYkqRznflhGB9Je0As7yL4KNtuO0Xsq5YzNVPsZBUhfLMN6RbICxIYI9uT2tgfknvoUETE3y5nAKAN8L47vDR3wcqqPommes9yTn5lMdifdDEcB2ere1IkpLKOYKhlHRdPXSMcrZCs7V6FMpF8dLPwyBZBNIZAugZDZD',
    //     numbers: [{
    //         number: '08283903830',
    //         name: 'Acme co. business',
    //         status: 'Verified',
    //         wabaId: '2783899993039'
    //     },
    //     {
    //         number: '08283934540',
    //         name: 'Nike Offers',
    //         status: 'Not verified',
    //         wabaId: '2783899993039'
    //     }]
    // };
    // props.hendleSetupSuccess(data);
  };

  const sampleResponse = (token) => {
    //let userAccessToken = "EAAEMrQZChTiQBALuHf28dpEujceQrWelU9FI4rxeSPRACgy57dGLOUh1kQjNj8Ufsi1Pc3ixRxJmnANPGMcEIz4Sb1wTCg9KVw8ZCFbJovW2WxWurUeRqL35Flu6ZArweAdTwmE0rRnS0KACq5rzepeZC4dv6kZBQyRZASvA8fJI8prHtJhqfXqFSM91yaaZBHcczsQzSmjlwZDZD";
    let userAccessToken = token;
    //let code = token;
    let params = {
      userAccessToken,
    };
    console.log("params", params);
    try {
      getPhoneNumbersForWABA(params)
        .then((res) => {
          const { error, response = [] } = res;
          if (!error || error === "false") {
            aggregatePhoneNumbers(res.response, token);
            console.log(res);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  const aggregatePhoneNumbers = (list, token) => {
    let finalList = [];
    list.map((element) => {
      let wabaDetails = {
        number: element.display_phone_number,
        name: element.verified_name,
        status: element.code_verification_status,
        wabaId: element.wabaId,
        phoneId: element.id,
      };
      finalList.push(wabaDetails);
    });
    let data = {
      token: token,
      numbers: finalList,
    };
    props.hendleSetupSuccess(data);
  };

  return (
    <div className="modal-container-backdrop">
      <div className="modal-container">
        <div className="w-1/2 h-auto bg-white rounded-md overflow-hidded">
          <div className="height-100">
            <div className="height-100">
              <div className="whatsapp-header-container">
                <div className="whatsapp-logo-container">
                  <img
                    height={30}
                    width={30}
                    color="#ffffff"
                    src={Whatsapp}
                    alt=""
                  />
                </div>
                <div
                  className="close-icon"
                  onClick={props.handleSetupModalClose}
                >
                  <img src={Close} alt="" />
                </div>
              </div>
              <div className="form-container">
                <div className="heading1">Setup WhatsApp Business API</div>
                <div className="heading2">
                  WhatsApp Business API powers communication with customers all
                  over the world. WhatsApp is a Facebook company and requires a
                  Facebook account and Facebook Business Manager to set up.
                </div>
                <div className="setup-steps-container-dv">
                  <div className="heading1">
                    Here is what you need to get started
                  </div>
                  <div className="setup-steps-desc-dv">
                    <div className="setup-steps-desc-bullet-dv">1</div>Be able
                    to login to your Facebook Business Manager account.
                  </div>
                  <div className="setup-steps-desc-dv">
                    <div className="setup-steps-desc-bullet-dv">2</div>Have a
                    company-owned phone number that is not linked to WhatsApp
                    before.
                  </div>
                  <div className="setup-steps-desc-dv">
                    <div className="setup-steps-desc-bullet-dv">3</div>Your
                    business’ legal address and details.
                  </div>
                  <div className="setup-steps-desc-dv">
                    <div className="setup-steps-desc-bullet-dv">4</div>Be able
                    to verify your Facebook Business.
                  </div>
                </div>
                <div className="buttons-container">
                  <div
                    className="back-button"
                    onClick={props.handleSetupModalClose}
                  >
                    Back
                  </div>
                  <div className="book-demo-button" onClick={fbLoginCall}>
                    Get started
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WabaIntegrationSetupModal;
