import { useState } from "react";
import "./number-selection.css";
import { registerPhoneNumber } from "../../conversations-main/waba-service";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";

import * as Sentry from "@sentry/react";
import { Close } from "../../../common/assets/icons";

const WabaNumberSelectionModal = (props) => {
  const [selectedNumber, setSelectedNumber] = useState(props?.data?.numbers[0]);
  const [viewType, setViewType] = useState("mobile");
  const [code, setCode] = useState("");
  const [codeSubmitted, setCodeSubmitted] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const selectNumber = (number) => {
    setSelectedNumber(number);
  };

  const nextStep = () => {
    setViewType("code");
  };

  const onCodeChange = (event) => {
    setCode(event.target.value);
  };

  const checkIfDigit = (code: string) => {
    return /^\d+$/.test(code);
  };
  const confirmNumber = () => {
    setCodeSubmitted(true);
    if (code.length != 6 || !checkIfDigit(code)) {
      Notify({
        type: "error",
        heading: "Invalid pin",
        message: "Please enter a valid 2FA pin",
      });
      return;
    }
    let body = {
      userAccessToken: props.data.token,
      pin: code,
      phonenumberId: selectedNumber.phoneId,
      wabaId: selectedNumber.wabaId,
    };
    try {
      setConfirmLoading(true);
      registerPhoneNumber(body)
        .then((res: any) => {
          if (res.err) {
            Notify({
              type: "error",
              heading: "2FA Setup Failed",
              message: "Two-factor authentication setup failed.",
            });
            setConfirmLoading(false);
          } else {
            props.henldeWabaNumbersModalSuccess(res.response);
            Notify({
              type: "success",
              heading: "2FA Setup Successful",
              message: "Two-factor authentication setup was successful.",
            });
            setConfirmLoading(false);
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log("Register phone number API error", error);
          Notify({
            type: "error",
            heading: "Error",
            message:
              "An error occured while setting up two-factor authentication",
          });
          setConfirmLoading(false);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log("Register phone number API error", error);
      Notify({
        type: "error",
        heading: "Error",
        message: "An error occured while setting up two-factor authentication",
      });
      setConfirmLoading(false);
    }
  };

  return (
    <div>
      {props.data.numbers.length > 0 ? (
        <div className="modal-container-backdrop">
          <div className="modal-container">
            <div
              className={
                viewType === "mobile" ? "modal-setup" : "modal-setup-30"
              }
            >
              <div className="height-100">
                <div className="height-100">
                  <div>
                    {viewType === "mobile" ? (
                      <div className="form-container">
                        <div className="flex justify-end">
                          <span
                            className="cursor-pointer"
                            onClick={props.handleWabaNumbersModalClose}
                          >
                            <img src={Close} alt="" />
                          </span>
                        </div>
                        <div className="heading1">
                          Select a phone number to register
                        </div>
                        <div className="heading2">
                          Select a WABA phone number to proceed with the
                          registration
                        </div>
                        <div className="waba-numer-container-dv">
                          <div className="waba-numer-container-dv-hd">
                            <div className="width-25">Phone number</div>
                            <div className="width-25">WABA name</div>
                            <div className="width-25">Status</div>
                            <div className="width-25">WABA ID</div>
                          </div>
                          <div>
                            {props?.data?.numbers.map((element, i) => {
                              return (
                                <div
                                  className="waba-numer-container-dv-hd"
                                  key={i}
                                >
                                  <div className="width-25">
                                    <label className="flex-gap-8 font-14-thin">
                                      <input
                                        type="radio"
                                        onChange={() => selectNumber(element)}
                                        checked={
                                          selectedNumber.number ===
                                          element.number
                                        }
                                      />
                                      {element.number}
                                    </label>
                                  </div>
                                  <div className="width-25">{element.name}</div>
                                  <div className="width-25">
                                    <span
                                      className={
                                        element.status === "NOT_VERIFIED"
                                          ? "not-verified-number"
                                          : "verified-number"
                                      }
                                    >
                                      {element.status === "NOT_VERIFIED"
                                        ? "Not Verified"
                                        : "Verified"}
                                    </span>
                                  </div>
                                  <div className="width-25">
                                    {element.wabaId}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="buttons-container">
                          <div className="book-demo-button" onClick={nextStep}>
                            Continue
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="form-container">
                        <div className="flex justify-end">
                          <span
                            className="cursor-pointer"
                            onClick={props.handleWabaNumbersModalClose}
                          >
                            <img src={Close} alt="" />
                          </span>
                        </div>
                        <div className="heading1">
                          Setup two-factor authentication
                        </div>
                        <div className="heading3">
                          Set a 6-digit registration PIN for this number. Save
                          and memorize this PIN as it can be requested later.
                        </div>
                        <div className="flex-basis-50">
                          <div
                            className={
                              (codeSubmitted && code.length != 6) ||
                              (codeSubmitted && !checkIfDigit(code))
                                ? "margin-top-4 input-container-error"
                                : "margin-top-4 input-container2"
                            }
                          >
                            <input
                              className="input-field font-14-thin"
                              type="text"
                              placeholder="Eg. 456789"
                              value={code}
                              onChange={onCodeChange}
                            />
                          </div>
                          <div style={{ height: "24px" }}>
                            <div className="error-message">
                              {(codeSubmitted && code.length != 6) ||
                              (codeSubmitted && !checkIfDigit(code))
                                ? "Please enter a 6 digit PIN."
                                : null}
                            </div>
                          </div>
                        </div>
                        <div className="buttons-container">
                          {confirmLoading ? (
                            <div className="text-sm leading-4 py-3 px-16 text-white bg-blue-75 rounded-[4px] border border-blue-75 cursor-not-allowed">
                              <Spinner className={"h-4 w-4"} />
                            </div>
                          ) : (
                            <div
                              className="book-demo-button"
                              onClick={confirmNumber}
                            >
                              Confirm
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WabaNumberSelectionModal;
