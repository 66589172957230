import axios from 'axios';
import {
  BASE_URL,
  TENANT_URL,
  BILLING_URL,
  AUDIENCE_URL,
  INTEGRATION_URL,
} from '../constants/baseUrl';
import { Notify } from '../components/Notify/Notify';

export const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await getRefreshedAccessToken();
      originalRequest.headers.Authorization = 'Bearer ' + access_token;
      return api(originalRequest);
    }
    handleErrorCodes(error);
    return Promise.reject(error);
  }
);

export const tenantApi = axios.create({
  baseURL: TENANT_URL,
});

tenantApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await getRefreshedAccessToken();
      originalRequest.headers.Authorization = 'Bearer ' + access_token;
      return api(originalRequest);
    }
    console.log(error);
    handleErrorCodes(error);
    return Promise.reject(error);
  }
);

export const billingApi = axios.create({
  baseURL: BILLING_URL,
});

billingApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await getRefreshedAccessToken();
      originalRequest.headers.Authorization = 'Bearer ' + access_token;
      return api(originalRequest);
    }
    handleErrorCodes(error);
    return Promise.reject(error);
  }
);

export const audienceApi = axios.create({
  baseURL: AUDIENCE_URL,
});

audienceApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await getRefreshedAccessToken();
      originalRequest.headers.Authorization = 'Bearer ' + access_token;
      return api(originalRequest);
    }
    handleErrorCodes(error);
    return Promise.reject(error);
  }
);

export const integrationApi = axios.create({
  baseURL: INTEGRATION_URL,
});

integrationApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await getRefreshedAccessToken();
      originalRequest.headers.Authorization = 'Bearer ' + access_token;
      return api(originalRequest);
    }
    handleErrorCodes(error);
    return Promise.reject(error);
  }
);

const handleErrorCodes = (error) => {
  switch (error?.response?.status) {
    case 504:
    case 502:
    case 503:
      Notify({
        type: 'error',
        heading: 'Error',
        message: 'Request Failed.',
      });
    default:
      Notify({
        type: 'error',
        heading: 'Error',
        message:
          error.response.data.response ??
          error.response.data.message ??
          error.response.data ??
          'Request Failed',
      });
  }
};

export const getRefreshedAccessToken = () => {
  let apiCallInstance;
  return new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem('refreshToken');
    let url = `/token`;
    let body = {
      grant_type: 'refresh_token',
      client_id: 'demo',
      scope: 'openid',
      refresh_token: refreshToken,
    };
    apiCallInstance = tenantApi
      .post(url, body)
      .then((response: any) => {
        try {
          const token = response.data.response.access_token;
          localStorage.removeItem('loginAccessToken');
          localStorage.setItem('loginAccessToken', token);
          resolve(token);
        } catch (error) {
          reject(error);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      window.location.assign('/401');
    });
};
