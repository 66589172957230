export const Notify = (props: any) => {
  let interval = setInterval(() => {
    const app = document.getElementById('app-parent-container');
    if (app) {
      clearInterval(interval);

      const notificationElement = document.createElement('div');
      // notificationElement.setAttribute('id', 'notification');
      notificationElement.id = 'notification';
      notificationElement.insertAdjacentHTML(
        'beforeend',

        `<div style="position:fixed; top:80px; right:30px; padding:12px 16px 16px 16px; border-radius:8px; box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1); z-index:10000; ${props.type === 'success'
          ? 'background:#F5FBF8;border:1px solid #52BD94'
          : 'background:#FDF4F4;border:1px solid #D14343'
        }">
        <div style="display:flex; justify-items-space-between; align-items:flex-start; gap:16px;">
        <div style="margin-top:5px">
      
       ${props.type === 'success'
          ? `<svg
       width="16"
       height="16"
       viewBox="0 0 16 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <path
         fill-rule="evenodd"
         clip-rule="evenodd"
         d="M0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0C3.58 0 0 3.58 0 8ZM11.29 5.29C11.47 5.11 11.72 5 12 5C12.55 5 13 5.45 13 6C13 6.28 12.89 6.53 12.71 6.71L7.71 11.71C7.53 11.89 7.28 12 7 12C6.72 12 6.47 11.89 6.29 11.71L3.29 8.71C3.11 8.53 3 8.28 3 8C3 7.45 3.45 7 4 7C4.28 7 4.53 7.11 4.71 7.29L7 9.59L11.29 5.29Z"
         fill="#52BD94"
       />
     </svg>`
          : `<svg
     width="16"
     height="16"
     viewBox="0 0 16 16"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
     >
     <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="M7.99891 0.00585938C3.58167 0.00585938 0.00390625 3.58362 0.00390625 8.00086C0.00390625 12.4181 3.58167 15.9959 7.99891 15.9959C12.4161 15.9959 15.9939 12.4181 15.9939 8.00086C15.9939 3.58362 12.4161 0.00585938 7.99891 0.00585938ZM6.99953 12.9977V10.999H8.99829V12.9977H6.99953ZM6.99953 3.00399V9.99961H8.99829V3.00399H6.99953Z"
       fill="#B42318"
     />
     </svg>`
        }
       </div>
       <div style="${props.type === 'success' ? 'color:#317159' : 'color:#A73636'
        }; max-width:270px">
       <span
       style="display:block; margin-bottom:10px;font-weight:600; font-size:16px; line-height:24px "
     >
       ${props.heading}
     </span>
     <span style="display:block; font-weight:400; font-size:14px; line-height:24px "
            >
              ${props.message}
            </span>
     </div>
     <span id='notificationClose' style="font-weight:600; cursor:pointer; ${props.type === 'success' ? 'color:#054F31' : 'color:#7A271A'
        }"
      onclick="(() => {  
        const notification=document.getElementById('notification');
         if(notification) notification.remove()
      })()">
            ✕
          </span>
       </div>
        </div>`
      );
      app.appendChild(notificationElement);
      setTimeout(() => {
        const notification = document.getElementById('notification');
        if (notification) notification.remove();
      }, 5000);
    }
  }, 500);
};
