const ConvesationIconUrl =
  'https://mcassets.blob.core.windows.net/cdn/ConversationAssets';

export const Close = `${ConvesationIconUrl}/Close.svg`;
export const Conversations = `${ConvesationIconUrl}/Conversations.svg`;
export const Mail = `${ConvesationIconUrl}/Mail.svg`;
export const PlusCircle = `${ConvesationIconUrl}/PlusCircle.svg`;
export const Received = `${ConvesationIconUrl}/Received.svg`;
export const Sent = `${ConvesationIconUrl}/Sent.svg`;
export const Users = `${ConvesationIconUrl}/Users.svg`;
export const Whatsapp = `${ConvesationIconUrl}/Whatsapp.svg`;
export const WhatsappMix = `${ConvesationIconUrl}/WhatsappMix.svg`;
