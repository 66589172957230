import * as Sentry from "@sentry/react";
const loginAccessToken = localStorage.getItem("loginAccessToken");
import { api } from "../../../../marketing-cloud-main/src/common/services/common-api-service";

const config = {
  headers: { Authorization: `Bearer ${loginAccessToken}` }
};

export const getDemoStatus = () => {
  const url = `/account/request?channel=whatsapp`;
  return api.get(url, config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
}

export const addDemo = (body) => {
  // {
  //     "businessName": "Terragon",
  //     "businessEmail": "mkarjun@terragonltd.com",
  //     "businessAddress": "Test",
  //     "industry": "entertainment and leisure",
  //     "isRegistered": "yes",
  //     "isFbManagerAvailable": "yes",
  //     "isPhonenumberAvailable": "yes"
  //   }
  const url = `/account/request?channel=whatsapp`;
  return api.post(url, body, config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
}

export const getIndustries = () => {
  const url = `/industries`;
  return api.get(url).then((response) => {
    return response.data;
  })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
}

const getAllAccounts = () => {
  const url = `/industries`;
  return api.get(url, config).then((response) => {
    return response.data;
  })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
}

const createAccount = (body) => {
  // {
  //     "phonenumberId": "484575796253066",
  //     "pin": 123456,
  //     "userAccessToken": "EAAEMrQZChTiQBAGqJPZBUo72GAjy90spmVLb4FU1QpVCM8hH0BUlkZBsgrrf7A7z5Npz0NcIA933eyUENljIob0RA6r5oRZBCuo8jGLe3XZCaaO13iYdQRFz1pMEHWgvvGWBmPZBZASMfbS55713JWD8YI9NVybG8oebokrAGeFC5JhBVq5iEGnXgV9i9jv2DsfIWtcDILZCrQZDZD",
  //     "wabaId": "331537752118888",
  //     "userMetadata": "1234"
  // }
  const url = `/account?channel=whatsapp`;
  return api.post(url, body).then((response) => {
    return response.data;
  })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
}

export const fbLogin = (FB) => {
  return new Promise((resolve, reject) => {
    FB.login(
      (authResponse: any) => {
        if (authResponse.authResponse) {
          resolve(authResponse.authResponse);
        } else {
          reject();
        }
        return;
      },
      {
        config_id: '3515971918666156', // configuration ID goes here
        response_type: 'code',    // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        extras: {
          setup: {}
        }
      }
    );
  });
}

export const getPhoneNumbersForWABA = (params) => {
  let p = {
    headers: config.headers,
    params: params
  }
  const url = `/whatsapp/phonenumbers`;
  return api.get(url, p).then((response) => {
    return response.data;
  })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
}

export const registerPhoneNumber = (body) => {
  let params = { channel: 'whatsapp' }
  let p = {
    headers: config.headers,
    params: params
  }
  const url = `/account`;
  return api.post(url, body, p).catch((error) => {
    (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
  });
}
