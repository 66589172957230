import env from "../../../env.json";

export const BASE_URL = env.BASE_URL;
export const BILLING_URL = env.BILLING_URL;
export const AUDIENCE_URL = env.AUDIENCE_URL;
export const SURVEY_URL = env.SURVEY_URL;
export const TENANT_URL = env.TENANT_URL;
export const FRONTEND_BASE = env.FRONTEND_BASE;
export const PESAPAL_URL = env.PESAPAL_URL;
export const INTEGRATION_URL = env.INTEGRATION_URL
