import { api } from '../../../../marketing-cloud-main/src/common/services/common-api-service';
import * as Sentry from "@sentry/react";

export const getAccountsAndOverview = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/account/insight?channel=whatsapp`;
  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      (err.response) ? Sentry.captureMessage("Error", err.response) : Sentry.captureMessage("Error", err);
    });
};

export const getConversations = (
  phoneNumberId: number,
  itemsPerPage = 10,
  page = 1
) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/conversation?phonenuberId=${phoneNumberId}&pageSize=${itemsPerPage}&pageNum=${page}`;
  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      (err.response) ? Sentry.captureMessage("Error", err.response) : Sentry.captureMessage("Error", err);
    });
};

export const getTags = (id: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/tag`;
  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      (err.response) ? Sentry.captureMessage("Error", err.response) : Sentry.captureMessage("Error", err);
    });
};
