const Spinner = ({ className }) => {
  return (
    <div
      className={`${className} animate-spin grid items-center justify-center`}
    >
      <div
        className={`${className} border-2 border-t-blue-75 border-white rounded-full`}
      ></div>
    </div>
  );
};

export default Spinner;
